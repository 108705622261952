import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/inter";
import "@fontsource/roboto";
import "@fontsource/poppins";
import "@fontsource/comfortaa";
import "@fontsource/montserrat";
import { LbAppWrapper } from "@lb/frontend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LbAppWrapper>
    <App />
  </LbAppWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
