import { EmbeddedLayout, LbLogoLoading } from "@lb/frontend";
import { contractsClientUrls, templatesClientUrls } from "@lb/utils";
import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

const Matrix = lazy(() => import("./pages/Matrix"));
const MatrixCreateEdit = lazy(() => import("./pages/Matrix/CreateEdit"));
const Contracts = lazy(() => import("./pages/contracts"));
const CreateContract = lazy(() => import("./pages/contracts/Create"));
const EditContract = lazy(() => import("./pages/contracts/Edit"));
const ContractSummary = lazy(() => import("./pages/contracts/Summary"));
const RejectContract = lazy(() => import("./pages/contracts/Summary/Reject"));
const Templates = lazy(() => import("./pages/Templates"));
const AppLayout = lazy(() => import("./components/AppLayout"));

const router = createBrowserRouter([
  {
    path: "embedded",
    element: <EmbeddedLayout />,
    children: [
      {
        path: contractsClientUrls.slug,
        element: <Contracts isEmbedded={true} />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<LbLogoLoading />}>
        <AppLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={contractsClientUrls.slug} replace />,
      },
      {
        path: contractsClientUrls.slug,
        children: [
          {
            index: true,
            element: <Contracts />,
          },
          {
            path: contractsClientUrls.create.slug,
            element: <CreateContract />,
          },
          {
            path: contractsClientUrls.edit.slug,
            element: <EditContract />,
          },
          {
            path: contractsClientUrls.summary.slug,
            element: <ContractSummary />,
            children: [
              {
                path: contractsClientUrls.summary.review.slug,
                element: <RejectContract />,
              },
            ],
          },
        ],
      },
      {
        path: templatesClientUrls.slug,
        element: <Templates />,
      },
      { path: "matrix", element: <Navigate replace to={"/matrix/signtory"} /> },
      {
        path: contractsClientUrls.matrix.slug,
        children: [
          { index: true, element: <Matrix /> },
          {
            path: contractsClientUrls.matrix.create.slug,
            element: <MatrixCreateEdit />,
          },
          {
            path: contractsClientUrls.matrix.update.slug,
            element: <MatrixCreateEdit />,
          },
        ],
      },
    ],
  },
]);

export default router;
